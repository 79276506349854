import React from "react";
import { Button, Grid, InputAdornment, TextField, styled } from "@mui/material";
import { MailOutline, Send, Delete, Repeat } from "@mui/icons-material";

import { Link } from "react-router-dom";
import { UserContext } from "../contexts/userContext";

let baseUrl = "";

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
	baseUrl = "http://localhost:1337";
}

const CenterGrid = styled(Grid)({
	display: "flex",
	justifyContent: "center",
	alignItems: "flex-start",
	marginBottom: 1
});

interface IProps {
	email: string;
	emailData: {
		Verified: boolean;
		Completed: boolean;
	};
	showSnack: () => void;
}

const EmailForm = (props: IProps): JSX.Element => {
	const handleResendForm = (event: React.FormEvent<HTMLFormElement>): void => {
		event.preventDefault();

		fetch(`${baseUrl}/api/resendEmail`, {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
			},
			credentials: "include",
			method: "POST",
			mode: "cors",
			body: `email=${props.email}`
		});

		props.showSnack();
	};

	return (
		// TODO fix user context
		<UserContext.Consumer>
			{({ removeEmail }) => (
				<Grid container justifyContent="space-evenly" sx={{ margin: 1 }}>
					<CenterGrid item xs={12} sm={2}>
						<Button variant="contained" color="secondary" onClick={() => removeEmail(props.email)} startIcon={<Delete />}>
							Eyða
						</Button>
					</CenterGrid>
					{props.emailData.Completed ? (
						<CenterGrid item xs={12} sm={3}>
							<Link to="/results" state={{ email: props.email }}>
								<Button variant="contained" color="primary" startIcon={<Send />}>
									Sjá Niðurstöðu
								</Button>
							</Link>
						</CenterGrid>
					) : (
						""
					)}
					<CenterGrid item xs={12} sm={4}>
						<TextField
							defaultValue={props.email}
							size="small"
							variant="outlined"
							disabled
							error={!props.emailData.Verified}
							helperText={props.emailData.Verified ? "Staðfest" : "Ekki staðfest"}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<MailOutline />
									</InputAdornment>
								)
							}}
						/>
					</CenterGrid>
					<CenterGrid item xs={12} sm={3}>
						{props.emailData.Verified ? (
							<Link to="/process" state={{ email: props.email }}>
								<Button variant="contained" color="primary" startIcon={<Send />}>
									Hefja Öryggismat
								</Button>
							</Link>
						) : (
							<form onSubmit={handleResendForm}>
								<Button variant="contained" color="primary" type="submit" startIcon={<Repeat />}>
									Endursenda póst
								</Button>
							</form>
						)}
					</CenterGrid>
				</Grid>
			)}
		</UserContext.Consumer>
	);
};

export default EmailForm;
