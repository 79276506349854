import React from "react";

import { Container, Grid } from "@mui/material";

import TitledTextBox from "../components/TitledTextBox";

const FAQ = (): JSX.Element => {
	return (
		<Container maxWidth="md" sx={{ my: 8, mx: "auto" }}>
			<Grid container rowSpacing={4}>
				<Grid item>
					<TitledTextBox
						title="Spurning 1?"
						content={[
							"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tortor condimentum lacinia quis vel eros donec ac. Et magnis dis parturient montes nascetur ridiculus. Sed ullamcorper morbi tincidunt ornare massa eget egestas. Fames ac turpis egestas maecenas pharetra convallis posuere."
						]}
					/>
				</Grid>
				<Grid item>
					<TitledTextBox
						title="Spurning 2?"
						content={[
							"Viverra nibh cras pulvinar mattis nunc sed blandit libero. Egestas tellus rutrum tellus pellentesque eu. Sem et tortor consequat id porta nibh. Lectus sit amet est placerat. Volutpat commodo sed egestas egestas. Elementum nisi quis eleifend quam adipiscing."
						]}
					/>
				</Grid>
				<Grid item>
					<TitledTextBox
						title="Spurning 3?"
						content={[
							"Sit amet volutpat consequat mauris nunc congue nisi vitae suscipit. Nibh tortor id aliquet lectus proin nibh nisl condimentum id. Nunc mattis enim ut tellus elementum sagittis vitae et leo. Nibh praesent tristique magna sit amet purus. Ipsum dolor sit amet consectetur adipiscing."
						]}
					/>
				</Grid>
			</Grid>
		</Container>
	);
};

export default FAQ;
