import React from "react";

import { Alert, AlertColor, Snackbar } from "@mui/material";

interface IProps {
	open: boolean;
	message: string;
	severity: AlertColor;
	autoHideDuration?: number;
	handleClose: () => void | unknown;
}

const SnackbarAlert = (props: IProps): JSX.Element => {
	const { message, open, severity, autoHideDuration, handleClose } = props;
	return (
		<Snackbar
			sx={{ paddingBottom: 4 }}
			anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
			open={open}
			autoHideDuration={autoHideDuration || 6000}
			onClose={handleClose}
		>
			<Alert onClose={handleClose} severity={severity}>
				{message}
			</Alert>
		</Snackbar>
	);
};

export default SnackbarAlert;
