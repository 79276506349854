import React from "react";
import { Container, FormControlLabel, Switch, FormGroup, FormControl, FormLabel, Button, Stack, Box, Modal, RadioGroup, Radio, Paper, Grid } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import SnackbarAlert from "../components/SnackbarAlert";
import { UserContext } from "../contexts/userContext";

const Settings = (): JSX.Element => {
	const userContext = React.useContext(UserContext);
	const { user } = userContext;

	const [modalOpen, setModalOpen] = React.useState(false);
	const [popupShown, setPopupShown] = React.useState(false);

	const handleModalOpen = (): void => {
		setModalOpen(true);
	};

	const handleModalClose = (): void => {
		setModalOpen(false);
	};

	const handleSettingsChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { name, checked } = event.target;

		user.settings[name] = checked;
		window.localStorage.setItem("session", JSON.stringify(user));

		setTimeout(() => {
			setPopupShown(true);
		}, 5);

		fetch("/api/settings", {
			headers: {
				"Content-Type": "application/json"
			},
			credentials: "include",
			method: "POST",
			mode: "cors",
			body: JSON.stringify({
				settings: user.settings
			})
		});
	};

	const handlePrimaryEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { value } = event.target;

		user.primaryEmail = value;
		window.localStorage.setItem("session", JSON.stringify(user));

		setTimeout(() => {
			setPopupShown(true);
		}, 5);

		fetch("/api/setPrimaryEmail", {
			headers: {
				"Content-Type": "application/json"
			},
			credentials: "include",
			method: "POST",
			mode: "cors",
			body: JSON.stringify({
				email: user.primaryEmail
			})
		});
	};

	const handleClose = (): void => {
		setPopupShown(false);
	};

	return (
		<Container>
			<FormControl component="fieldset" variant="standard">
				<Stack sx={{ marginTop: 4 }} spacing={4}>
					<Box>
						<FormLabel>Aðgangsstillingar</FormLabel>
						<FormGroup>
							<FormControlLabel
								control={<Switch name="aggressive" checked={user.settings.aggressive} onChange={handleSettingsChange} />}
								label="Ítarleg leit (sendir mikið af tölvupóstum)"
							/>
							<FormControlLabel
								control={<Switch name="monitor" checked={user.settings.monitor} onChange={handleSettingsChange} />}
								label="Eftirlit"
							/>
						</FormGroup>
					</Box>
					<Box>
						<FormLabel>Eftirlits Netfang</FormLabel>
						{Object.entries(user.emails).length > 0 ? (
							<RadioGroup name="primary-email" value={user.primaryEmail} onChange={handlePrimaryEmailChange}>
								{Object.keys(user.emails)
									.filter((email: string) => user.emails[email].Verified === true)
									.map((email: string) => (
										<FormControlLabel key={email} value={email} control={<Radio />} label={email} />
									))}
							</RadioGroup>
						) : (
							<FormLabel> (Vantar netfang)</FormLabel>
						)}
					</Box>
					<Box>
						<Button variant="contained" color="secondary" size="small" type="submit" startIcon={<DeleteIcon />} onClick={handleModalOpen}>
							Eyða aðgangi
						</Button>
					</Box>
				</Stack>
			</FormControl>
			<Modal open={modalOpen} onClose={handleModalClose}>
				<Paper
					elevation={4}
					sx={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						width: 400,
						p: 4
					}}
				>
					<p>Ertu viss um að þú viljir eyða aðganginum þínum?</p>
					<Grid container justifyContent="space-between">
						<Grid item>
							<form action={"/api/deleteAccount"} method="GET">
								<Button variant="contained" color="secondary" type="submit" startIcon={<DeleteIcon />}>
									Eyða aðgangi
								</Button>
							</form>
						</Grid>
						<Grid item>
							<Button variant="contained" color="primary" type="submit" style={{ display: "inline-block" }} onClick={handleModalClose}>
								Hætta við
							</Button>
						</Grid>
					</Grid>
				</Paper>
			</Modal>

			<SnackbarAlert severity="success" open={popupShown} handleClose={handleClose} autoHideDuration={1500} message="Stillingar þínar hafa verið vistaðar!" />
		</Container>
	);
};

export default Settings;
