import React from "react";

import { CircularProgress } from "@mui/material";

const CenteredLoading = (): JSX.Element => {
	return (
		<CircularProgress
			sx={{
				position: "absolute",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)"
			}}
		/>
	);
};

export default CenteredLoading;
