import React, { lazy, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { User } from "./types";

import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// elements
import Header from "./components/Header";
import Footer from "./components/Footer";

// Pages
import HomePage from "./pages/Home";
import UserHomePage from "./pages/UserHome";
import Contact from "./pages/Contact";
import About from "./pages/About";
import FAQ from "./pages/FAQ";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Settings from "./pages/Settings";
import { invalidUser, UserContext } from "./contexts/userContext";
import CenteredLoading from "./components/CenteredLoading";

// Lazy loaded pages
const ResultsPage = lazy(() => import("./pages/Results"));
const ProcessPage = lazy(() => import("./pages/Process"));

const DEBUG = (process.env.NODE_ENV && process.env.NODE_ENV === "development");
const baseUrl = DEBUG ? "http://localhost:1337" : "";

const theme = createTheme({
	palette: {
		primary: {
			main: "#3F51B5"
		},
		secondary: {
			main: "#F50057"
		}
	},
	components: {
		MuiDivider: {
			styleOverrides: {
				root: {
					marginLeft: 16,
					marginRight: 16
				}
			}
		}
	},
	typography: {
		h6: {
			fontWeight: 400
		}
	}
});

const App = (): JSX.Element => {
	const [user, setUser] = React.useState<User>({ ...invalidUser, ...JSON.parse(window.localStorage.getItem("session") ?? "{}") });

	const removeEmail = (email: string): void => {
		delete user.emails[email];
		fetch(`${baseUrl}/api/removeEmail`, {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
			},
			credentials: "include",
			method: "POST",
			mode: "cors",
			body: `email=${email}`
		});
	};

	const addEmail = (email: string): void => {
		setUser({ 
			...user, 
			emails: { 
				...user.emails, 
				[email]: { 
					Verified: user.ssn === "0509805109" || DEBUG ? true : false, 
					Completed: false 
				} 
			} 
		});

		fetch(`${baseUrl}/api/addEmail`, {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
			},
			credentials: "include",
			method: "POST",
			mode: "cors",
			body: `email=${encodeURIComponent(email)}`
		});
	};

	const invalidateUser = (): void => {
		setUser({
			...user,
			valid: false,
			loaded: true
		});
		window.localStorage.setItem("session", "{}");
	};

	useEffect(() =>{
		fetch("/api/profile", {
			method: "GET",
			credentials: "include",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				"Access-Control-Allow-Credentials": "true"
			}
		})
			.then((res) => {
				if (res.status === 200) return res.json();
				else return Promise.reject();
			})
			.then((resJSON) => {
				setUser({ ...{ loaded: true }, ...resJSON });
				window.localStorage.setItem("session", JSON.stringify(resJSON));
			})
			.catch(() => {
				invalidateUser();
			});
	},[]);

	
	window.localStorage.setItem("session", JSON.stringify(user));
	return (
		<ThemeProvider theme={theme}>
			<UserContext.Provider
				value={{
					user: user,
					addEmail: addEmail,
					removeEmail: removeEmail,
					invalidateUser: invalidateUser
				}}
			>
				<CssBaseline />
				<div style={{ marginBottom: "-64px", minHeight: "100vh" }}>
					<Header />
					<Routes>
						<Route path="/" element={<HomePage />} />
						<Route path="contact" element={<Contact />} />
						<Route path="about" element={<About />} />
						<Route path="faq" element={<FAQ />} />
						<Route path="terms" element={<Terms />} />
						<Route path="privacy" element={<Privacy />} />
						{user.valid && (
							<>
								<Route
									path="results"
									element={
										<React.Suspense fallback={<CenteredLoading />}>
											<ResultsPage />
										</React.Suspense>
									}
								/>
								<Route
									path="process"
									element={
										<React.Suspense fallback={<CenteredLoading />}>
											<ProcessPage />
										</React.Suspense>
									}
								/>
								<Route path="profile" element={<UserHomePage />} />
								<Route path="profile/settings" element={<Settings />} />
							</>
						)}
						{user.loaded && <Route path="*" element={<Navigate replace to="/" />} />}
					</Routes>
					<div style={{ height: "64px" }}></div>
				</div>
				<Footer />
			</UserContext.Provider>
		</ThemeProvider>
	);
};

export default App;
