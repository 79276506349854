import React from "react";
import { Link } from "react-router-dom";

import logo from "../img/logo.svg";
import "../style.css";

import {
	AppBar,
	Toolbar,
	Typography,
	Divider,
	ButtonBase,
	IconButton,
	Drawer,
	List,
	ListItem,
	ListItemText,
	styled,
	Menu,
	MenuItem,
	ListItemIcon,
	useMediaQuery
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import InfoIcon from "@mui/icons-material/Info";
import EmailIcon from "@mui/icons-material/Email";
import PolicyIcon from "@mui/icons-material/Policy";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import LoginIcon from "@mui/icons-material/Login";

import { UserContext } from "../contexts/userContext";
import { loginUrl, logoutUrl } from "../libs/urls";
import { IMenuItem } from "../types";

const Logo = styled("img")(({ theme }) => ({
	height: "45px",
	marginRight: theme.spacing(2),
	filter: "invert(100%) sepia(1%) saturate(2%) hue-rotate(237deg) brightness(106%) contrast(100%)"
}));

const TitleSpan = styled("span")({
	flexGrow: 1
});

const clearSession = () => {
	window.localStorage.setItem("session", "{}");
	window.location.href = logoutUrl;
};

const Header = (): JSX.Element => {
	const [openDrawer, setOpenDrawer] = React.useState(false);
	const [menuAnchorEl, setMenuAnchorEl] = React.useState<HTMLElement | null>(null);

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"), { defaultMatches: true });

	const userContext = React.useContext(UserContext);
	const user = userContext.user;

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setMenuAnchorEl(event.currentTarget);
	};

	const handleCloseMenu = () => {
		setMenuAnchorEl(null);
	};

	const toggleDrawer = (isOpen: boolean) => () => {
		setOpenDrawer(isOpen);
	};

	const menuItems: IMenuItem[] = [
		{
			link: "/faq",
			text: "Spurt og svarað",
			icon: <QuestionAnswerIcon />
		},
		{
			link: "/about",
			text: "Um Öryggismat",
			icon: <InfoIcon />
		},
		{
			link: "/contact",
			text: "Hafa samband",
			icon: <EmailIcon />
		}
	];

	if (isMobile) {
		menuItems.push(
			{
				link: "/terms",
				text: "Skilmálar",
				icon: <AssignmentTurnedInIcon />
			},
			{
				link: "/privacy",
				text: "Persónuverndarstefna",
				icon: <PolicyIcon />,
				divider: true
			}
		);

		if (user.valid) {
			menuItems.unshift(
				{
					link: "/profile",
					text: "Mínar síður",
					icon: <HomeIcon />
				},
				{
					link: "/profile/settings",
					text: "Stillingar",
					icon: <SettingsIcon />
				}
			);

			menuItems.push({
				onClick: clearSession,
				text: "Útskrá",
				icon: <LogoutIcon />
			});
		} else {
			menuItems.push({
				onClick: () => (window.location.href = loginUrl),
				text: "Innskrá",
				icon: <LoginIcon />
			});
		}
	}
	return (
		<AppBar sx={{ flexGrow: 1, paddingRight: 2 }} position="static">
			<Toolbar>
				<Link to="/">
					<Logo src={logo}></Logo>
				</Link>
				<Link to="/">
					<ButtonBase disableRipple={true}>
						<Typography variant="h5">Öryggismat</Typography>
					</ButtonBase>
				</Link>
				<TitleSpan></TitleSpan>
				{isMobile ? (
					<>
						<IconButton color="inherit" onClick={toggleDrawer(true)}>
							<MenuIcon />
						</IconButton>
						<Drawer id="drawer-nav" anchor="right" open={openDrawer} onClose={toggleDrawer(false)}>
							<List>
								{menuItems.map((item) => {
									if (item.link) {
										return (
											<ListItem button key={item.text} component={Link} to={item.link} divider={item.divider}>
												<ListItemIcon>{item.icon}</ListItemIcon>
												<ListItemText primary={item.text} />
											</ListItem>
										);
									} else {
										return (
											<ListItem button key={item.text} onClick={item.onClick} divider={item.divider}>
												<ListItemIcon>{item.icon}</ListItemIcon>
												<ListItemText primary={item.text} />
											</ListItem>
										);
									}
								})}
							</List>
						</Drawer>
					</>
				) : (
					<>
						{menuItems.map((item) => {
							if (item.link) {
								return (
									<>
										<ButtonBase key={item.text} component={Link} to={item.link} sx={{ paddingX: 1 }}>
											<Typography variant="h6">{item.text}</Typography>
										</ButtonBase>
										<Divider variant="middle" orientation="vertical" flexItem />
									</>
								);
							} else {
								return (
									<>
										<ButtonBase key={item.text} onClick={item.onClick} sx={{ paddingX: 1 }}>
											<Typography variant="h6">{item.text}</Typography>
										</ButtonBase>
										<Divider variant="middle" orientation="vertical" flexItem />
									</>
								);
							}
						})}
						{user.valid ? (
							<>
								<IconButton color="inherit" size="large" onClick={handleClick} sx={{ padding: 0 }}>
									<AccountCircle fontSize="large" />
								</IconButton>
								<Menu
									open={Boolean(menuAnchorEl)}
									onClose={handleCloseMenu}
									anchorEl={menuAnchorEl}
									anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
									transformOrigin={{ vertical: "top", horizontal: "center" }}
								>
									<Link to="/profile">
										<MenuItem>
											<ListItemIcon>
												<HomeIcon fontSize="small" />
											</ListItemIcon>
											<Typography variant="h6">Mínar síður</Typography>
										</MenuItem>
									</Link>
									<Divider />
									<Link to="/profile/settings">
										<MenuItem>
											<ListItemIcon>
												<SettingsIcon fontSize="small" />
											</ListItemIcon>
											<Typography variant="h6">Stillingar</Typography>
										</MenuItem>
									</Link>
									<MenuItem onClick={clearSession}>
										<ListItemIcon>
											<LogoutIcon fontSize="small" />
										</ListItemIcon>
										<Typography variant="h6">Útskrá</Typography>
									</MenuItem>
								</Menu>
							</>
						) : (
							<ButtonBase href={loginUrl}>
								<Typography variant="h6">Innskrá</Typography>
							</ButtonBase>
						)}
					</>
				)}
			</Toolbar>
		</AppBar>
	);
};

export default Header;
