/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";
import { IUserContext, User } from "../types";

export const invalidUser: User = {
	loaded: false,
	valid: undefined,
	name: "",
	ssn: "",
	primaryEmail: "",
	emails: {
		"": {
			Verified: false,
			Completed: false
		}
	},
	settings: {}
};

export const defaultUserContext: IUserContext = {
	user: invalidUser,
	addEmail: () => {},
	removeEmail: () => {},
	invalidateUser: () => {}
};

const UserContext = React.createContext(defaultUserContext);

export { UserContext };
