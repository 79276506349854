import React, { ReactElement } from "react";

import { Typography, Grid, Divider, styled, Hidden, useMediaQuery, useTheme, Breakpoint } from "@mui/material";

const Icon = styled("div")({
	fontSize: "8rem"
});

type colorType = "initial" | "inherit" | "primary" | "secondary" | "textPrimary" | "textSecondary" | "error";

interface IProps {
	title?: string;
	content: string[];
	icon?: ReactElement;
	color?: colorType;
	divider?: boolean;
	hideIconBelow?: Breakpoint;
}

const TitledTextBox = (props: IProps): JSX.Element => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
	const isHidden = props.hideIconBelow ? isMobile : false;

	const contentSize = props.icon && !isHidden ? 8 : 12;
	return (
		<Grid container columnSpacing={3}>
			{props.icon && !isHidden && (
				<Grid item xs={"auto"}>
					<Icon>{props.icon}</Icon>
				</Grid>
			)}
			<Grid item xs={contentSize}>
				{props.title && (
					<Typography sx={{ marginBottom: 1, fontSize: "2rem" }} color={props.color} variant="h4">
						{props.title}
					</Typography>
				)}
				{props.content.map((text) => (
					<Typography sx={{ marginTop: 1, fontSize: "1.4rem !important", color: "#606060" }} key={text}>
						{text}
					</Typography>
				))}
				{props.divider && <Divider />}
			</Grid>
		</Grid>
	);
};

export default TitledTextBox;
