import React from "react";

import { Container, Grid } from "@mui/material";

import TitledTextBox from "../components/TitledTextBox";

const Privacy = (): JSX.Element => {
	return (
		<Container maxWidth="md" sx={{ my: 8, mx: "auto", margin: "50px auto" }}>
			<Grid container rowSpacing={4}>
				<Grid item>
					<TitledTextBox
						title="Upplýsingar sem við geymum"
						content={[
							"Nafn, kennitölu og símanúmer (ef rafrænt skilríki er notað) í gegnum Ísland.is",
							"Netfang/netföng við netfangs skráningu.",
							"Þjónustur, leka og einkunn við keyrslu Öryggismats á skilgreindu netfangi."
						]}
					/>
				</Grid>
				<Grid item>
					<TitledTextBox
						title="Hvernig við notum þínar upplýsingar"
						content={[
							"Syndis ehf. áskilur sér rétt að nota ópersónubundnar upplýsingar fyrir sjálfan sig og þriðju aðila.",
							"Ópersónubundnar upplýsingar eru eftirfarandi, lekar og einkunnir."
						]}
					/>
				</Grid>
				<Grid item>
					<TitledTextBox
						title="Hverjum deilum við upplýsingunum með"
						content={[
							"Syndis ehf. deilir ekki persónulegum upplýsingum með þriðja aðila.",
							"Til meðhöndlunar á persónulegum upplýsingum þarf aðili að vera bundin trúnaðarsamningi."
						]}
					/>
				</Grid>
			</Grid>
		</Container>
	);
};

export default Privacy;
