// Get login and logout url
let logout = "https://oryggismat.is/api/logout";
let login = "https://innskraning.island.is/?id=syndis.is&path=/oryggismat";

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
	logout = "http://localhost:1337/api/logout";
	login = "https://innskraning.island.is/?id=syndis.is&path=/oryggismat-local";
} else if (process.env.REACT_APP_ENVIRONMENT === "test") {
	logout = "https://slabdev.oryggismat.is/api/logout";
	login = "https://innskraning.island.is/?id=syndis.is&path=/oryggismat-dev";
}

export const loginUrl = login;
export const logoutUrl = logout;

// Get socket url
let socket = "wss://oryggismat.is";

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
	socket = "ws://localhost:1337";
} else if (process.env.REACT_APP_ENVIRONMENT === "test") {
	socket = "wss://slabdev.oryggismat.is";
}

export const socketUrl = socket;
