import React from "react";
import { Button, InputAdornment, TextField, Box, styled } from "@mui/material";
import { MailOutline, Message, Person, Title } from "@mui/icons-material";
import SnackbarAlert from "../components/SnackbarAlert";

let baseUrl = "";

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
	baseUrl = "http://localhost:1337";
}

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+\w{2,}))$/;

const DivMargin = styled("div")({
	margin: "15px auto",
	width: "50em"
});

interface IPostData {
	email: string;
	name: string;
	title: string;
	content: string;
}

const Contact = (): JSX.Element => {
	const [postData, setPostData] = React.useState<IPostData>({
		email: "",
		name: "",
		title: "",
		content: ""
	});
	const [sent, setSent] = React.useState<boolean>(false);
	const [emailValid, setEmailValid] = React.useState<boolean>(true);
	const [nameMissing, setNameMissing] = React.useState<boolean>(false);
	const [titleMissing, setTitleMissing] = React.useState<boolean>(false);
	const [contentMissing, setContentMissing] = React.useState<boolean>(false);

	const handleFieldUpdate = (e: { target: { value: string; name: string } }): void => {
		switch (e.target.name) {
			case "email":
				setEmailValid(e.target.value == "" || e.target.value.match(emailRegex) !== null);
				break;
			case "name":
				setNameMissing(false);
				break;
			case "title":
				setTitleMissing(false);
				break;
			case "content":
				setContentMissing(false);
				break;
		}
		setPostData({ ...postData, [e.target.name]: e.target.value });
	};

	const handleContact = (event: React.FormEvent<HTMLFormElement>): boolean => {
		event.preventDefault();
		if (postData.content !== "" && postData.title !== "" && postData.name !== "") {
			if (postData.email.match(emailRegex)) {
				fetch(`${baseUrl}/api/contact`, {
					headers: {
						"Content-Type": "application/json; charset=UTF-8"
					},
					credentials: "include",
					method: "POST",
					mode: "cors",
					body: JSON.stringify(postData)
				});
				setPostData({ name: "", email: "", title: "", content: "" });
				setSent(true);
			} else {
				setEmailValid(false);
			}
		} else {
			setNameMissing(postData.name === "");
			setTitleMissing(postData.title === "");
			setContentMissing(postData.content === "");
			setEmailValid(postData.email.match(emailRegex) !== null);
		}

		return false;
	};

	const handleClose = (event?: React.SyntheticEvent, reason?: string): void => {
		if (reason === "clickaway") {
			return;
		}

		setSent(false);
	};

	return (
		<Box sx={{ my: 8, mx: "auto", textAlign: "center" }}>
			<form onSubmit={handleContact} action={`${baseUrl}/api/contact`} method="post">
				<DivMargin>
					<TextField
						error={nameMissing}
						fullWidth
						name="name"
						value={postData.name}
						onChange={handleFieldUpdate}
						label="Fullt Nafn"
						variant="outlined"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Person />
								</InputAdornment>
							)
						}}
					/>
				</DivMargin>
				<DivMargin>
					<TextField
						error={!emailValid}
						fullWidth
						name="email"
						value={postData.email}
						onChange={handleFieldUpdate}
						label="Netfang"
						variant="outlined"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<MailOutline />
								</InputAdornment>
							)
						}}
					/>
				</DivMargin>
				<DivMargin>
					<TextField
						error={titleMissing}
						fullWidth
						name="title"
						value={postData.title}
						onChange={handleFieldUpdate}
						label="Titill"
						variant="outlined"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Title />
								</InputAdornment>
							)
						}}
					/>
				</DivMargin>
				<DivMargin>
					<TextField
						error={contentMissing}
						fullWidth
						name="content"
						value={postData.content}
						onChange={handleFieldUpdate}
						label="Skilaboð"
						multiline
						rows={10}
						variant="outlined"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Message />
								</InputAdornment>
							)
						}}
					/>
				</DivMargin>
				<Button variant="contained" type="submit">
					Senda
				</Button>
			</form>

			<SnackbarAlert severity="success" open={sent} handleClose={handleClose} message="Stillingar þínar hafa verið vistaðar!" />
		</Box>
	);
};

export default Contact;
