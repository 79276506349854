import React from "react";

import { AccountCircle, AlternateEmail, Business, VpnLock } from "@mui/icons-material";
import { Button, Grid, Typography, Divider, Container, styled, useMediaQuery, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { Link } from "react-router-dom";

import TitledTextBox from "../components/TitledTextBox";

import fingerPrint from "../img/Fingerprint.webp";
import screenshot from "../img/screenshot.webp";
import passwordZombie from "../img/passwordZombie.webp";
import dataLeak from "../img/dataLeak.webp";
import digitalAwareness from "../img/digitalAwareness.webp";
import cloud from "../img/cloud.webp";
import placeholderImage from "../img/placeholderImage.webp";

import { UserContext } from "../contexts/userContext";
import { loginUrl } from "../libs/urls";

const Image = styled("img")({
	border: "10px solid lightgray",
	borderBottom: "none",
	borderRadius: "60px 60px 0 0",
	maxWidth: "100%"
});

const Home = (): JSX.Element => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"), { defaultMatches: true });

	const userContext = React.useContext(UserContext);
	const { user } = userContext;

	return (
		<Grid container rowGap={2} sx={{ "& > *": { paddingY: 8 } }}>
			{/* Section 1 */}
			<Grid item xs={12}>
				<Grid container justifyContent="space-evenly" alignItems="center" spacing={4}>
					<Grid item xs={10} md={5}>
						<Typography variant="h4" color="primary">
							Hvað vita meinfýsnir hakkarar um þig?
						</Typography>
						<TitledTextBox
							content={[
								"Sjáðu hvað þitt Öryggisfótspor er út frá þínum stafrænu fingraförum sem þú notar fyrir þjónustur á Internetinu. Vita meinfýsnir hakkarar þín lykilorð eða viðkvæmar persónuupplýsingar? Með Öryggismati Syndis skilgreinum við þitt Öryggisfótspor og metum þína áhættu gegn tölvuþrjótum. Á sama tíma getur þú borið þig saman við Öryggisvísitölu Íslands og séð hvernig þú stendur í samanburði við aðra."
							]}
						/>
						<Box textAlign="center" sx={{ marginTop: 1 }}>
							{user.valid ? (
								<Link to="/profile">
									<Button variant="contained" color="primary" size="large">
										Hvert er mitt Öryggismat?
									</Button>
								</Link>
							) : (
								<Button variant="contained" color="primary" size="large" href={loginUrl}>
									Hvert er mitt Öryggismat?
								</Button>
							)}
						</Box>
					</Grid>
					<Grid item xs={10} md={5} sx={{ lineHeight: 0 }}>
						<Image src={screenshot}></Image>
					</Grid>
				</Grid>
				<Divider sx={{ marginBottom: 8 }} />
				<Grid justifyContent="space-around" alignItems="center" container>
					<Grid item xs={10} md={6}>
						<Grid container alignItems="center" spacing={4}>
							<Grid item xs={12}>
								<TitledTextBox
									title="Deildu þinni reynslu"
									content={[
										"Besta leiðin til að auka stafrænt öryggi okkar allra er með aukinni þekkingu og fræðslu. Miðlaðu þinni niðurstöðu og hvernig þú bættir þitt öryggi svo aðrir geti dregið úr líkum á því að verða fórnarlamb tölvuglæpamanna"
									]}
								/>
							</Grid>
							<Grid item xs={12}>
								<TitledTextBox
									title="Stafræn fingraför í skýinu"
									content={[
										"Það er nær ómögulegt að nota Internetið án þess að skilja eftir sig allskyns ummerki og ýmiskonar stafræn fingraför sem auðveldlega má rekja. Veistu hver þín stafrænu fingraför eru?"
									]}
								/>
							</Grid>
							<Grid item xs={12}>
								<TitledTextBox
									title="Þitt öryggismat"
									content={[
										"Skráðu þig inn í kerfið og kláraðu gagnvirka Öryggismatið. Kerfið mun svo leiðbeina þér hvernig auka megi þitt stafræna öryggi svo þú sért ólíklegra fórnarlamb tölvuglæpamanna"
									]}
								/>
							</Grid>
						</Grid>
					</Grid>
					{!isMobile && (
						<Grid item xs={4}>
							<img width="100%" src={cloud}></img>
						</Grid>
					)}
				</Grid>
			</Grid>
			{/* Section 2 */}
			<Grid item xs={12} sx={{ bgcolor: "#e9ecf0" }}>
				<Grid justifyContent="space-around" alignItems="center" container>
					{!isMobile && (
						<Grid item xs={4}>
							<img height="50%" width="100%" src={fingerPrint}></img>
						</Grid>
					)}
					<Grid item xs={10} md={6}>
						<Box sx={{ marginBottom: 4 }}>
							<TitledTextBox
								title="Stafrænt fingrafar í skýinu"
								content={["Við erum með ýmiskonar stafræn fingraför á netinu sem hver sem er getur notað til að komast að upplýsingum um okkur."]}
							/>
						</Box>
						<Grid container alignItems="center">
							<Grid item xs={12}>
								<TitledTextBox
									title="Netföng"
									content={["Hægt er að nota netföng til að kortleggja flestar þær þjónustur sem við notum á netinu."]}
									icon={<AlternateEmail fontSize="inherit" />}
									hideIconBelow="sm"
								/>
							</Grid>
							<Grid item xs={12}>
								<TitledTextBox
									title="Notendanöfn"
									content={["Ef við veljum okkur notendanafn sem er einkvæmt þá er auðvelt að rekja stafrænar þjónustur okkar."]}
									icon={<AccountCircle fontSize="inherit" />}
									hideIconBelow="sm"
								/>
							</Grid>
							<Grid item xs={12}>
								<TitledTextBox
									title="Efnisveitur, þjónustur og gögn"
									content={[
										"Allar þær þjónustur sem við notum og skráum okkur í taka á móti okkar gögnum og mikið af þeim gögnum er aðgengilegt hverjum sem er."
									]}
									icon={<Business fontSize="inherit" />}
									hideIconBelow="sm"
								/>
							</Grid>
							<Grid item xs={12}>
								<TitledTextBox
									title="Bættu þitt öryggi"
									content={[
										"Með vitneskju um þitt fingrafar getur þú bætt þitt eigið stafræna öryggi og dregið úr líkum að þú verðir óþarfa fórnarlamb tölvuglæpamanna."
									]}
									icon={<VpnLock fontSize="inherit" />}
									hideIconBelow="sm"
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			{/* Section 3 */}
			<Grid item xs={12}>
				<Container>
					<Grid container justifyContent="center" rowSpacing={4}>
						<Grid item xs={12}>
							<img width="100%" src={passwordZombie}></img>
						</Grid>

						<Grid item xs={6}>
							<Typography variant="h4">Yfir 5.5 milljarður lykilorða lekið.</Typography>
						</Grid>

						<Grid item xs={6}>
							<Typography variant="h4">Lykilorðin okkar eru opinber.</Typography>
						</Grid>

						<Grid item xs={6}>
							<Typography variant="h4">Þekkt lykilorð af yfir 3 milljörðum netfanga.</Typography>
						</Grid>

						<Grid item xs={6}>
							<Typography variant="h4">Hvernig hafa gagnalekar áhrif á mig?</Typography>
							<TitledTextBox
								content={[
									`Það getur haft áhrif á okkar persónuvernd og þær
										upplýsingar sem við höfum að geyma, eitt af slíku
										eru okkar lykilorð sem eru í dag mjög aðgengileg.`
								]}
							/>
						</Grid>
					</Grid>
				</Container>
			</Grid>
			{/* Section 4 */}
			
			<Grid item xs={12} sx={{ bgcolor: "#e9ecf0" }}>
				<Grid container justifyContent="center">
					{!isMobile && (
						<Grid item xs={4}>
							<img width="100%" src={dataLeak}></img>
						</Grid>
					)}
					<Grid item xs={10} md={8}>
						<Grid container rowSpacing={4}>
							<Grid item xs={8}>
								<TitledTextBox
									title="Stöðugir gagnalekar á persónugreinanlegum gögnum"
									content={[
										`Það eru ekki bara lykilorð sem má finna í öllum þeim opinberu
											innbrotum og gagnalekum heldur allskyns ítarupplýsingar og
											jafnvel samskipti okkar. Gott er að vita hvort okkar
											stafræna fingrafar er hluti að þeim gagnalekum svo hægt sé að meta áhrif þeirra á okkur.`
									]}
								/>
							</Grid>
							<Grid item xs={8}>
								<TitledTextBox
									title="Facebook - 500 milljónir færsla um notendur"
									content={[
										`Facebook tilkynnti í Apríl 2021 að yfir 500 milljón færslur um 
											notendur þeirra sem innihéldu upplýsingar um þá, m.a.
											símanúmer. Þetta eru upplýsingar sem
											meinfýsnir aðilar geta nýtt sér.`
									]}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			{/* Section 5 */}
			<Grid item xs={12}>
				<Grid container justifyContent="space-evenly">
					{!isMobile && (
						<Grid item xs={2}>
							<img width="100%" src={digitalAwareness}></img>
						</Grid>
					)}
					<Grid item xs={10} md={6}>
						<Grid container>
							<Grid item xs={10}>
								<TitledTextBox
									title="Finndu út hvert þitt stafræna fótspor er"
									content={[
										`Öryggismat Syndis hjálpar þér að sjá hvert þitt 
											stafræna fótspor er á sjálfvirkan hátt út frá þeim
											stafrænu fingraförum sem eftir okkur liggja í skýinu.`
									]}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Typography align="center" variant="h4" gutterBottom>
							Samstarfsaðilar / stuðningsaðilar
						</Typography>
					</Grid>
					<Grid item xs={12} md={3} sx={{ textAlign: "center" }}>
						<img src={placeholderImage} />
					</Grid>
					<Grid item xs={12} md={3} sx={{ textAlign: "center" }}>
						<img src={placeholderImage} />
					</Grid>
					<Grid item xs={12} md={3} sx={{ textAlign: "center" }}>
						<img src={placeholderImage} />
					</Grid>
					<Grid item xs={12} md={3} sx={{ textAlign: "center" }}>
						<img src={placeholderImage} />
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Home;
