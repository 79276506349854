import React from "react";
import { Typography, Container, Divider, InputAdornment, TextField, Button, Grid, styled } from "@mui/material";
import { MailOutline, Add } from "@mui/icons-material";

import EmailForm from "../components/EmailForm";

import SnackbarAlert from "../components/SnackbarAlert";
import { UserContext } from "../contexts/userContext";

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+\w{2,}))$/;

const CenterGrid = styled(Grid)({
	display: "flex",
	justifyContent: "center",
	alignItems: "flex-start",
	marginBottom: 1
});

const UserHome = (): JSX.Element => {
	const [email, setEmail] = React.useState("");
	const [emailValid, setEmailValid] = React.useState(true);
	const [sentSnack, setSentSnack] = React.useState(false);
	const [resentSnack, setResentSnack] = React.useState(false);
	const [errorSnack, setErrorSnack] = React.useState(false);
	const [errorMessage, setErrorMessage] = React.useState("Netfangið er ógilt.");

	const userContext = React.useContext(UserContext);
	const { user, addEmail } = userContext;

	const handleEmailChange = (e: { target: { value: string } }): void => {
		setEmail(e.target.value);
		setEmailValid(e.target.value == "" || e.target.value.match(emailRegex) !== null);
	};

	const handleAddForm = (): boolean => {
		const emailNormalized = email.toLowerCase();
		if (email.match(emailRegex) && user.emails[emailNormalized] === undefined) {
			addEmail(emailNormalized);

			setEmail("");
			setEmailValid(true);
			setSentSnack(true);
		} else {
			const errorMessage = emailNormalized.match(emailRegex) ? "Netfang er nú þegar til." : "Netfangið er ógilt.";
			setEmailValid(false);
			setErrorMessage(errorMessage);
			setErrorSnack(true);
		}
		return false;
	};

	const handleClose = (event?: React.SyntheticEvent, reason?: string): void => {
		if (reason === "clickaway") {
			return;
		}

		setSentSnack(false);
		setResentSnack(false);
		setErrorSnack(false);
	};

	const showResendSnack = (): void => {
		setResentSnack(true);
	};

	return (
		<Container maxWidth="md" sx={{ flexGrow: 1, marginTop: 12, marginBottom: 12 }}>
			<Typography variant="body1" sx={{ marginBottom: 8, textAlign: "center" }}>
				{user.name}, vinsamlega staðfestu þau netföng sem þú vilt að séu hluti af þínu öryggismati.
			</Typography>
			{Object.entries(user.emails).map(([email, emailData]) => (
				<div key={email}>
					<EmailForm showSnack={() => showResendSnack()} email={email} emailData={emailData} />
					<Divider />
				</div>
			))}
			<form action="javascript:void(0);" onSubmit={handleAddForm}>
				<Grid container justifyContent="space-evenly" sx={{ margin: 1 }}>
					<CenterGrid item xs={12} sm={6}>
						<TextField
							error={!emailValid}
							autoComplete="email"
							name="email"
							size="small"
							value={email}
							onChange={handleEmailChange}
							variant="outlined"
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<MailOutline />
									</InputAdornment>
								)
							}}
						/>
					</CenterGrid>
					<CenterGrid item xs={12} sm={3}>
						<Button variant="contained" color="primary" type="submit" startIcon={<Add />}>
							Bæta við netfangi
						</Button>
					</CenterGrid>
				</Grid>
			</form>
			<SnackbarAlert severity="success" open={sentSnack} handleClose={handleClose} message="Staðfestingar póstur hefur verið sendur!" />
			<SnackbarAlert severity="success" open={resentSnack} handleClose={handleClose} message="Staðfestingar póstur hefur verið endursendur!" />
			<SnackbarAlert severity="error" open={errorSnack} handleClose={handleClose} message={errorMessage} />
		</Container>
	);
};

export default UserHome;
