import React from "react";
import { Link } from "react-router-dom";

import logo from "../img/logo.svg";

import { AppBar, Toolbar, Typography, Divider, ButtonBase, IconButton, styled, useMediaQuery } from "@mui/material";
import { Facebook, Twitter, Copyright } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

const Logo = styled("img")({
	height: "30px",
	marginRight: 1,
	filter: "invert(100%) sepia(1%) saturate(2%) hue-rotate(237deg) brightness(106%) contrast(100%)"
});

const TitleSpan = styled("span")({
	flexGrow: 1
});

const Footer = (): JSX.Element => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"), { defaultMatches: true });

	return (
		<AppBar position="static" sx={{ flexGrow: 1, paddingRight: 2, bottom: 0, height: 64 }}>
			<Toolbar>
				<Logo src={logo}></Logo>
				<Typography variant="body1">Syndis Labs (Alpha)</Typography>
				<TitleSpan></TitleSpan>
				{!isMobile && (
					<>
						<Link to="/terms">
							<ButtonBase>
								<Typography variant="body1">Skilmálar</Typography>
							</ButtonBase>
						</Link>
						<Divider variant="middle" orientation="vertical" flexItem />
						<Link to="/privacy">
							<ButtonBase>
								<Typography variant="body1">Persónuverndarstefna</Typography>
							</ButtonBase>
						</Link>
						<Divider variant="middle" orientation="vertical" flexItem />
					</>
				)}
				<IconButton color="inherit" href="https://twitter.com/thesyndis">
					<Twitter />
				</IconButton>
				<IconButton color="inherit" href="https://www.facebook.com/thesyndis/">
					<Facebook />
				</IconButton>
				<Typography variant="caption">
					<Copyright fontSize="inherit" /> 2021
				</Typography>
			</Toolbar>
		</AppBar>
	);
};

export default Footer;
