import React from "react";

import { Container, Grid } from "@mui/material";

import TitledTextBox from "../components/TitledTextBox";

const About = (): JSX.Element => {
	return (
		<Container maxWidth="md" sx={{ my: 8, mx: "auto" }}>
			<Grid container rowSpacing={4}>
				<Grid item>
					<TitledTextBox
						title="Hvað er Öryggismat.is"
						content={[
							"Öryggismat Syndis Labs er verkefni sem er hugsað sem leið til að auka öryggisvitund almennings og sýna fram á hvað meinfýsnir hakkarar geta vitað um okkur."
						]}
					/>
				</Grid>
				<Grid item>
					<TitledTextBox
						title="Af hverju á ég að nota Öryggismat"
						content={[
							"Það að vera að nota þjónustur Internetsins hefur það í för með sér að við skiljum eftir okkur fótspor og stafræn fingraför sem má í flestum tilfellum rekja til okkar sem einstaklinga. Að auki eru tölvuárásir og gagnalekar mjög víðtækir í dag og gögn um okkur má finna í þessum lekum."
						]}
					/>
				</Grid>
				<Grid item>
					<TitledTextBox
						title="Hver er tilgangurinn með Öryggismat"
						content={[
							"í þessu kerfi viljum við reyna að opna augu almennings fyrir þessum möguleika með því að útbúa einstaklingsmiðaða nálgun sem er hönnuð fyrir venjulegt fólk svo það megi komast betur að þeirra stafræna fótspori og hvort og þá hvaða upplýsingar má finna um þau í opinberum lekum."
						]}
					/>
				</Grid>
				<Grid item>
					<TitledTextBox
						title="Hvað gerir Öryggismat"
						content={[
							"Kerfið er ekki hugsað sem fullkomin lausn heldur sem viðmið og að það mat sem sé lagt á okkur er eingöngu mat. Kerfið sækir opinberar upplýsingar úr þekktum lekum m.a. lykilorð, 'dulkóðuð' lykilorð (hakkaföll) og símanúmer. Ásamt því skannar það allskyns þjónustur og finnur út hvort þú hefur aðgang á þeim. Í lokinn er tekið allt saman og reiknuð út einkun sem sýnir þér viðmið á því hvar þú stendur þig í þínum öryggismálum á netinu."
						]}
					/>
				</Grid>
			</Grid>
		</Container>
	);
};

export default About;
